
export const isEmpty = (data) => {
	if (data === null) {
		return true;
	}
	if (typeof data === 'undefined') {
		return true;
	}
	if (typeof data.length === 'number' && data.length === 0) {
		return true;
	}
	if (typeof data === 'number' && data === 0) {
		return true;
	}
	if (typeof data === 'object' && Object.keys(data).length === 0) {
		return true;
	}
	return false;
};

export const twoChars = (val) => {
	return ('0' + val).slice(-2);
};

export const formatDate = (value, format = 'YYYY-MM-DD') => {
	if (isEmpty(value) === true) {
		return value;
	}

	// fix dates with number (like 2022 => 1970)
	if (typeof value === 'number') {
		value = String(value);
	}

	const date = value === 'now' ? new Date() : new Date(value);

	//console.log('formatDate:', value, date, format);

	switch (format) {
		case 'YY':
			return `${String(date.getFullYear()).substr(2, 2)}`;
		case 'YYYY':
			return `${date.getFullYear()}`;
		case 'MM/DD':
			return `${twoChars(date.getMonth() + 1)}/${twoChars(date.getDate())}`;
		case 'DD/MM':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}`;
		case 'DD/MM/YY':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}/${date.getFullYear()}`;
		case 'YYYY-MM-DD':
			return `${date.getFullYear()}-${twoChars(date.getMonth() + 1)}-${twoChars(date.getDate())}`;
	}
	return value;
};

export const rankingTypeName = (code) => {
	switch (code) {
		case 'ITE':
			return 'Étape';
		case 'ITG':
			return 'Général';
	}
	return code;
};

export const eventColorFg = (code) => {
	switch (code) {
		case 'IBE':
			return 'black';
	}
	return 'white';
};
export const eventColorBg = (code) => {
	switch (code) {
		case 'START':
			return '#000f77';
		case 'IME': // mountain
			return '#ff0008';
		case 'IPE': // points
		case 'IPA': // points
			return '#0c7c00';
		case 'CLM': // Contre la montre
			return 'white';
		case 'IBE': // bonus
		case 'INTER': // Général Points Autres Sprints
			return '#ffff00'; // yellow
		case 'IOE':
			return '#000f77'; // blue
	}
	return 'black';
};

export const eventGroupIcon = (id, firstLetter, position) => {
	return `/images/groups/${position}.svg`;
	/*
	 if (id === '00000000-8888-8888-0000-000000000000') {
	 return '/images/groups/P.svg';
	 }
	 if (['C'].includes(firstLetter) === true) {
	 //return `/images/groups/${firstLetter}-${position}.svg`;
	 return `/images/groups/${position}.svg`;
	 }
	 return `/images/groups/${firstLetter}.svg`;
	 */
};

export const eventIcon = (category, code, needOpacity = false) => {
	const suffix = needOpacity === true ? '-op' : '';
	if (isEmpty(category) === false) {
		return `/images/poi/${category}${suffix}.svg`;
	}
	if (['IPE', 'IPA'].includes(code) === true) {
		return `/images/poi/sprint${suffix}.svg`;
	}
	if (['INTER'].includes(code) === true) {
		return `/images/poi/inter${suffix}.svg`;
	}
	if (['IOE'].includes(code) === true) {
		return `/images/poi/blue${suffix}.svg`;
	}
	if (['END'].includes(code) === true) {
		return `/images/poi/arrival.svg`;
	}
	if (['START'].includes(code) === true) {
		return `/images/poi/start.svg`;
	}
	if (['CLM'].includes(code) === true) {
		return `/images/poi/clm${suffix}.svg`;
	}
	if (['IBE'].includes(code) === true) {
		return `/images/poi/bonus${suffix}.svg`;
	}
	return '';
};

export const formatComma = (value) => String(value).replace('.', ',');

export const capitalize = (string) => {
	if (isEmpty(string) === true) {
		return string;
	}
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getStageEvents = (currentStage) => {
	const getEventRanking = (eventId) => {
		if (currentStage === null) {
			return null;
		}
		if (isEmpty(currentStage.rankingTypes) === true) {
			return null;
		}
		const ranking = currentStage.rankingTypes.find((r) => r.id === eventId);
		if (typeof ranking === 'undefined') {
			return null;
		}
		return ranking;
	};

	let globalEvents = [
		{
			code: 'START',
			num: 0,
			event: '0',
			name: '',
			km: 0,
			type: 'S',
		},
	];
	if (currentStage !== null) {
		if (isEmpty(currentStage.events) === false) {
			globalEvents = [...globalEvents, ...currentStage.events];
		}
		if (isEmpty(currentStage.intermediates) === false) {
			globalEvents = [...globalEvents, ...currentStage.intermediates];
		}
		globalEvents.push({
			code: 'END',
			num: 0,
			event: '0',
			name: '',
			km: currentStage.length,
			type: 'A',
		});
	}
	globalEvents = globalEvents.filter((e) => e.hidden !== true);

	globalEvents.map(ev => {
		if (isEmpty(ev.id) === true) {
			ev.hasResult = false;
			return;
		}
		const eventRanking = getEventRanking(ev.id);
		ev.hasResult = (isEmpty(eventRanking) === false && isEmpty(eventRanking.rankings) === false);
	});

	return globalEvents.sort((a, b) => {
		if (a.km > b.km) {
			return 1;
		}
		if (a.km < b.km) {
			return -1;
		}
		if (a.km === b.km) {
			return 0;
		}
	});

};
