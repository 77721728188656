
export default () => {
	return {
		template: '<div class="text-center"><i class="fas fa-2x fa-spinner animation-rotation"></i></div>',
		data() {
			return {
			};
		},
	};
};
