import ApexCharts from 'apexcharts';
import {isEmpty, formatComma, eventIcon, eventGroupIcon, getStageEvents} from '../components/tools';

const parseFloat = (value, precision) => {
	return formatComma((Math.round(value * 100) / 100).toFixed(precision));
};

export default (template) => {
	return {
		props: {
			currentStage: Object,
			color: String,
		},
		template,
		watch: {
			'$reloadKey.count'() {
				this.notify();
			},
			'currentStage'() {
				// necessary timeout
				setTimeout(() => {
					this.drawChart();
				}, 0);
			},
		},
		data() {
			return {
				loading: false,
				chart: null,
			};
		},
		mounted() {
			this.drawChart();
		},
		computed: {
			stageEvents() {
				return getStageEvents(this.currentStage);
			},
		},
		methods: {
			drawChart() {
				if (this.$refs.chart === null) {
					console.error('Could not find chart element');
					return;
				}

				this.loading = true;

				if (this.chart !== null) {
					this.chart.destroy();
					this.chart = null;
				}

				if (isEmpty(this.currentStage) === true) {
					console.log('Invalid stage');
					this.loading = false;
					return;
				}
				if (isEmpty(this.currentStage.roads) === true) {
					console.log('Invalid stage roads');
					this.loading = false;
					return;
				}

				this.chart = new ApexCharts(document.querySelector(`#chart-${this.currentStage.id}`), this.getChartOptions());
				this.chart.render();

				setTimeout(() => {
					this.loading = false;
				}, 500);
			},
			getChartOptions() {
				const labels = this.currentStage.roads.map(e => e.startKilo);
				const elevationData = this.currentStage.roads.map(e => e.elevation);
				const maxElevation = Math.max(...elevationData, 1200);

				const nearestLiveRoads = this.currentStage.roads.filter(r => r.startKilo < this.currentStage.start);
				const countRoadsRaced = nearestLiveRoads.length;

				const elevationData1 = elevationData.slice(0, nearestLiveRoads.length);
				const elevationData2 = [...Array.from({length: countRoadsRaced - 1}, () => 0), ...elevationData.slice(countRoadsRaced, elevationData.length)];

				const options = {
					chart: {
						height: 200,
						type: 'area',
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						animations: {
							enabled: false,
						},
					},
					// color the line
					colors: ['black'],
					series: [{data: elevationData1}, {data: elevationData2}],
					legend: {
						show: false,
					},
					// OK => KM
					xaxis: {
						type: 'numeric',
						tickAmount: 10,
						categories: labels,
						// OK // how many decimals
						decimalsInFloat: 1,
						axisTicks: {
							show: false,
						},
						labels: {
							formatter: (e) => {
								return parseFloat(e, 1) + " km";
							},
						},
					},
					// OK: elevation
					yaxis: {
						type: 'numeric',
						min: 0,
						max: maxElevation,
						labels: {
							formatter: (e) => {
								return parseInt(e) + " m";
							},
						},
					},
					stroke: {
						curve: 'smooth', // OK
						width: 2, // OK
					},
					fill: {
						type: 'gradient', // OK
						gradient: {
//							shade: 'dark',
//							type: 'horizontal',
//							shadeIntensity: 0.5,
//							inverseColors: true,
							opacityFrom: 0.8,
							opacityTo: 0.4,
						},
						colors: [(isEmpty(this.color) === false ? this.color : 'red'), 'white'],
					},
					// OK, labels on every points of the line
					dataLabels: {
						enabled: false,
					},
					annotations: {
						// OK
						xaxis: [
							{
								x: this.currentStage.start,
								borderColor: 'black',
							}
						],
						// OK
						points: [...this.getEventsPoints(), ...this.getGroupsPoints()],
					},
					// OK
					grid: {
						show: true,
						borderColor: '#94949435',
						strokeDashArray: 10,
					},
					tooltip: {
						enabled: false,
					},
				};

				return options;
			},
			getEventsPoints() {
				const points = [];

				const stageEvents = this.stageEvents.filter((e) => ['START', 'END'].includes(e.code) === false);

				for (let event of stageEvents) {
					let y = this.currentStage.roads.find(r => r.startKilo >= event.km) || {elevation: 0};

					// is last event is further the last road...
					if (y.elevation === 0) {
						if (event.km > this.currentStage.roads[this.currentStage.roads.length -1].startKilo) {
							y = this.currentStage.roads[this.currentStage.roads.length -1];
						}
					}
					
					const icon = eventIcon(event.category, event.code, event.hasResult === false);

					const newPoint = {
						id: event.id,
						x: event.km,
						y: y.elevation,
						// we do not need marker as we use image
						marker: {
							size: 0,
//							fillColor: eventColorBg(event.code),
//							strokeColor: 'white',
						},
//						label: {
//							text: event.name,
//							offsetY: 0,
//							style: {
//								color: 'white',
//								background: eventColorBg(event.code),
//							},
//						},
						click: () => {
							if (event.hasResult === true) {
								this.$selectedEvent.id = event.id;
							}
						},
					};

					if (icon !== '') {
						newPoint.image = {
							path: icon,
							offsetY: -15,
						};
					}

					const prevPointsAtSameElevation = points.find((p) => p.x === event.km);
					if (typeof prevPointsAtSameElevation !== 'undefined' && typeof prevPointsAtSameElevation.image !== 'undefined' && typeof newPoint.image !== 'undefined') {
						newPoint.image.offsetY = prevPointsAtSameElevation.image.offsetY - 20;
					}

					points.push(newPoint);
				}

				return points;
			},
			getGroupsPoints() {
				if (isEmpty(this.currentStage.groups) === true) {
					return [];
				}

				const points = [];
				const groups = this.currentStage.groups.sort((a, b) => b.distance - a.distance);
				//const counterGroups = {};

				for (let group of groups) {
					const y = this.currentStage.roads.find(e => e.startKilo >= group.distance) || {elevation: 0};
					//if (typeof counterGroups[group.name] === 'undefined') {
					//	counterGroups[group.name] = 0;
					//}
					//counterGroups[group.name]++;
					//const icon = eventGroupIcon(group.id, group.name.charAt(0), counterGroups[group.name]);
					const icon = eventGroupIcon(group.id, group.name.charAt(0), group.position);

					const newPoint = {
						id: group.id,
						x: group.distance,
						y: y.elevation,
						click: () => {
							this.$selectedEvent.group = group;
						},
					};

					if (icon !== '') {
						newPoint.image = {
							path: icon,
							offsetY: -15,
						};
					}

					const prevPointsAtSameElevation = points.find((p) => (p.x - group.distance) < 40);
					if (typeof prevPointsAtSameElevation !== 'undefined' && typeof prevPointsAtSameElevation.image !== 'undefined' && typeof newPoint.image !== 'undefined') {
						if (isEmpty(prevPointsAtSameElevation.cpt) === true) {
							prevPointsAtSameElevation.cpt = 1;
						} else {
							prevPointsAtSameElevation.cpt++;
						}
						newPoint.image.offsetY = prevPointsAtSameElevation.image.offsetY - (20 * prevPointsAtSameElevation.cpt);
					}

					points.push(newPoint);
				}

				return points;
			},
			updateChart() {
				this.chart.updateOptions(this.getChartOptions());
			},
			async notify() {
				console.log('notify!', this.$reloadKey);

				if (this.$reloadKey.message.type === 'live-group') {
					//console.log(this.$reloadKey.message.id, this.$route.params.stageId);
					if (this.$reloadKey.message.id === this.$route.params.stageId) {

						if (this.$reloadKey.message.id === this.$route.params.stageId) {
							// append the new data
							for (let key of Object.keys(this.$reloadKey.message.data)) {
								this.currentStage[key] = this.$reloadKey.message.data[key];
							}

							this.updateChart();
						}
					}
				}
			},
		},
	};
};
