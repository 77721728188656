
export default () => {
	return {
		template: `<template v-if="image !== null"><img :src="'/images/stages-types/' + image.icon" :alt="image.alt" style="height: 20px;"></template>`,
		watch: {
			'type'() {
				this.image = this.getTypeIcon(this.type);
			},
		},
		props: {
			type: String,
			color: String,
		},
		data() {
			return {
				image: null,
			};
		},
		created() {
			this.image = this.getTypeIcon(this.type);
		},
		methods: {
			getTypeIcon(type) {
				const color = this.color ? this.color: 'white';
				switch (type) {
					case 'LIG':
						return {icon: `road-${color}.svg`, alt: 'Road'};
					case 'CLI':
						return {icon: `chronometer-${color}.svg`, alt: 'Chrono'};
				}
				return null;
			},
		},
	};
};
