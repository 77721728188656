import Config from '../components/config';
import {reactive} from 'vue';

export default (globalProperties) => {

	let eventSource = null;

	const connect = () => {
		eventSource = new window.EventSource(Config.serverRest + '/updates');

		eventSource.onopen = () => {
			globalProperties.$reloadKey.status = 'connected';
		};

		eventSource.onerror = () => {
			if (eventSource.readyState === window.EventSource.CLOSED) {
				console.error('perte de connexion définitive avec le serveur...');
				globalProperties.$reloadKey.status = 'closed';
			}
			if (eventSource.readyState === window.EventSource.CONNECTING) {
				console.error('perte de connexion temporaire avec le serveur...');
				globalProperties.$reloadKey.status = 'connecting';
			}
		};

		eventSource.addEventListener('message', event => {
			const message = JSON.parse(event.data);
			//console.log('Message', message);

			globalProperties.$reloadKey.message = {
				id: message.id,
				type: message.type,
				data: message.data,
			};
			globalProperties.$reloadKey.count++;
		});
	};

	let pageVisibile = true;

	document.addEventListener('visibilitychange', () => {
		pageVisibile = (document.visibilityState === 'visible');

		if (pageVisibile === false) {
			eventSource.close();
		} else {
			connect();
		}
	});

	globalProperties.count = 0;
	globalProperties.status = 'connecting';
	globalProperties.message = null;
	globalProperties.$reloadKey = reactive({
		count: globalProperties.count,
		status: globalProperties.status,
	});

	connect();
};
