
export default () => {
	return {
		template: `<template v-if="isEmpty(nationality) === false"><img :src="Config.serverRest + '/images/flags/' + nationality + '.png'" :alt="nationality" class="image-flag" :style="'height: ' + this._height  + 'px;'"></template>`,
		props: {
			nationality: String,
			height: Number,
		},
		data() {
			return {
				_height: 12,
			};
		},
		created() {
			if (this.height) {
				this._height = this.height;
			}
		},
	};
};
