import {isEmpty} from '../components/tools';

export default (template) => {

	return {
		template,
		props: {
			backgroundColor: String,
			image: String,
			imageLink: String,
		},
		computed: {
			getBgColor() {
				if (isEmpty(this.backgroundColor) === false) {
					return '#' + this.backgroundColor;
				}
				return '#d6d6d6';
			},
			getHeaderImage() {
				if (isEmpty(this.image) === false) {
					return this.image;
				}
				return null;
			},
			getHeaderImageLink() {
				if (isEmpty(this.imageLink) === false) {
					return this.imageLink;
				}
				return null;
			},
		},
		updated() {
			window.setTimeout(this.refreshMainMarginBottom, 500);
		},
		data() {
			return {
			};
		},
		methods: {
			refreshMainMarginBottom() {
				if (this.getHeaderImage === null) {
					return;
				}
				if (this.isModeIframe() === true) {
					return;
				}
				if (document.querySelector('main') === null) {
					window.setTimeout(this.refreshMainMarginBottom, 500);
					return;
				}
				try {
					const mainTop = getComputedStyle(document.querySelector('main'))['margin-bottom'];
					const imgHeight = getComputedStyle(document.querySelector('footer img'))['height'];
					document.querySelector('main').style.marginBottom = (parseInt(mainTop) + parseInt(imgHeight)) + 'px';
					//console.log(getComputedStyle(document.querySelector('main'))['margin-bottom']);

				} catch (exception) {
					window.setTimeout(this.refreshMainMarginBottom, 500);
					return;
				}
			},
		},
	};
}
