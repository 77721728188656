import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';

import {eventIcon, eventGroupIcon, isEmpty, getStageEvents} from '../components/tools';

let map = null;
let pathLayer = null;
let markersLayer = null;
let groupsLayer = null;
let polylines = null;

const iconSize = 18;

export default (template) => {
	return {
		props: {
			currentStage: Object,
		},
		template,
		watch: {
			'$reloadKey.count'() {
				this.notify();
			},
		},
		data() {
			return {
			};
		},
		computed: {
			stageEvents() {
				return getStageEvents(this.currentStage);
			},
		},
		methods: {
			drawMap() {
				const pointsCoords = this.currentStage.roads.map(e => [e.lt, e.lg]);

				if (map === null) {
					map = leaflet.map('map');
					map.setView([51.505, -0.09], 2);
					leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
						maxZoom: 19,
						attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
					}).addTo(map);
				}
				if (map !== null) {
					setTimeout(function () {
						//console.log('resize!');
						window.dispatchEvent(new Event('resize'));

						setTimeout(function () {
							// zoom the map to the polyline
							polylines && map.fitBounds(polylines.getBounds());
						}, 1000);
					}, 1000);
				}

				//////////// create path
				if (pathLayer !== null) {
					pathLayer.removeFrom(map);
				}
				pathLayer = leaflet.layerGroup();

				let latlngs = [];
				for (let pointsCoord of pointsCoords) {
					latlngs.push(pointsCoord);
				}
				polylines = leaflet.polyline(latlngs, {color: 'red'});
				pathLayer.addLayer(polylines);
				pathLayer.addTo(map);

				// zoom the map to the polyline
				map.fitBounds(polylines.getBounds());

				///////////// add events markers
				if (markersLayer !== null) {
					markersLayer.removeFrom(map);
				}
				markersLayer = leaflet.layerGroup();

				for (let event of this.stageEvents) {
					let point = this.currentStage.roads.find(e => e.startKilo >= event.km);
					const iconUrl = eventIcon(event.category, event.code);

					if (['START'].includes(event.code) === true) {
						point = this.currentStage.roads[0];
					}
					if (['END'].includes(event.code) === true) {
						point = this.currentStage.roads[this.currentStage.roads.length - 1];
					}
					if (isEmpty(point) === true) {
						console.log('no point for:', event);
						continue;
					}

					if (iconUrl !== '') {
						let icon = leaflet.icon({
							iconUrl,
							iconSize: [iconSize, iconSize],
							iconAnchor: [iconSize / 2, iconSize / 2],
							popupAnchor: [0, iconSize / 2 * -1],
						});
						const marker = leaflet.marker([point.lt, point.lg], {
							icon,
							title: event.name,
						});
						markersLayer.addLayer(marker);
					} else {
						console.log('no icon for', event);
					}
				}

				markersLayer.addTo(map);

				this.updateGroupMarkers();
			},
			async updateGroupMarkers() {
				//console.log('updateGroupMarkers!!');

				if (map === null) {
					return;
				}

				if (groupsLayer !== null) {
					groupsLayer.removeFrom(map);
				}

				if (isEmpty(this.currentStage.groups) === true) {
					return;
				}

				groupsLayer = leaflet.layerGroup();

				const groups = this.currentStage.groups.sort((a, b) => b.distance - a.distance);
				//const counterGroups = {};

				for (let group of groups) {
					const point = this.currentStage.roads.find(e => e.startKilo >= group.distance);
					if (group.lg <= 0) {
						group.lg = point.lg;
					}
					if (group.lt <= 0) {
						group.lg = point.lt;
					}

					//if (typeof counterGroups[group.name] === 'undefined') {
					//	counterGroups[group.name] = 0;
					//}
					//counterGroups[group.name]++;
					//const iconUrl = eventGroupIcon(group.id, group.name.charAt(0), counterGroups[group.name]);
					const iconUrl = eventGroupIcon(group.id, group.name.charAt(0), group.position);

					if (iconUrl !== '') {
						let icon = leaflet.icon({
							iconUrl,
							iconSize: [iconSize, iconSize],
							iconAnchor: [iconSize / 2, iconSize / 2],
							popupAnchor: [0, iconSize / 2 * -1],
						});
						const marker = leaflet.marker([point.lt, point.lg], {
							icon,
							title: group.name,
						});
						groupsLayer.addLayer(marker);
					} else {
						console.log('no icon for', group);
					}
				}

				groupsLayer.addTo(map);
			},
			async notify() {
				//console.log('notify!', this.$reloadKey);

				if (this.$reloadKey.message.type === 'live-group') {
					//console.log(this.$reloadKey.message.id, this.$route.params.stageId);
					if (this.$reloadKey.message.id === this.$route.params.stageId) {
						if (this.$reloadKey.message.id === this.$route.params.stageId) {
							// append the new data
							for (let key of Object.keys(this.$reloadKey.message.data)) {
								this.currentStage[key] = this.$reloadKey.message.data[key];
							}
							this.updateGroupMarkers();
						}
					}
				}
			},
			openMapModal() {
				const modalEl = document.querySelector('#mapModal');
				const instance = new window.mdb.Modal(modalEl);
				
				instance.toggle();

				this.drawMap();
			},
		},
	};
};
