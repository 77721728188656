import {createApp, reactive} from 'vue/dist/vue.esm-bundler';
import {createRouter, createWebHistory} from 'vue-router';

// modules
import RealTime from './modules/realtime';
import i18n from './modules/i18n';
import Stats from './modules/stats';

// components
import Config from './components/config';
import {isEmpty, formatDate, capitalize} from './components/tools';
import Header from './components/header';
import Footer from './components/footer';
import Loader from './components/loader';
import Event from './components/event';
import Panel from './components/panel';
import IconStageType from './components/iconStageType';
import Flag from './components/flag';
import bibFlag from './components/bibFlag';
//import StageChart from './components/stageChart';
import StageApexChart from './components/stageApexChart';
import StageMap from './components/stageMap';
import PopupPalmares from './components/popupPalmares';
// pages
import Competitions from './pages/competitions';
import Competition from './pages/competition';
import Stage from './pages/stage';
import CoupeDeFrance from './pages/coupeDeFrance';
import CoupeDeFranceCompetition from './pages/coupeDeFranceCompetition';

const routes = [
	{path: '/:filter?', component: Competitions(require('/src/pages/competitions.html')), name: 'competitions'},
	{path: '/competition/:id', component: Competition(require('/src/pages/competition.html')), name: 'competition'},
	{path: '/competition/:competitionId/:stageId/:tabId?/:rankingTabId?', component: Stage(require('/src/pages/stage.html')), name: 'stage'},
	{path: '/coupeDeFrance/:gender?', component: CoupeDeFrance(require('/src/pages/coupeDeFrance.html')), name: 'coupeDeFrance'},
	{path: '/coupeDeFrance/:gender/:year/:competitionId?/:rankingTabId?', component: CoupeDeFranceCompetition(require('/src/pages/coupeDeFranceCompetition.html')), name: 'coupeDeFranceCompetition'},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

const filters = {
	uppercase: (string) => {
		return string.toUpperCase();
	},
	twoChars: (s) => {
		s = s + '';
		if (s.length === 1) {
			s = '0' + s;
		}
		return s;
	},
};

const app = createApp();
// set reload key
RealTime(app.config.globalProperties);

Stats.init(4);

app.config.globalProperties.$capitalize = capitalize;
app.config.globalProperties.$uppercase = filters.uppercase;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.isEmpty = isEmpty;
app.config.globalProperties.Config = Config;
app.config.globalProperties.Stats = Stats;
app.config.globalProperties.$selectedEvent = reactive({
	id: null,
	event: null,
	group: null,
});
app.config.globalProperties.$rider = reactive({
	rider: null,
	details: null,
});

app.config.globalProperties.isModeIframe = () => {
	switch (window.location.hostname) {
		case 'iframe.localhost':
		case 'iframe.cycling.matsport.com':
		case 'iframe.test.cycling.matsport.com':
			return true;
	}
	return false;
};

app.use(i18n);
app.use(router);

// components
app.component('cyc-header', Header(require('/src/components/header.html')));
app.component('cyc-footer', Footer(require('/src/components/footer.html')));
app.component('cyc-loader', Loader());
app.component('cyc-event', Event(require('/src/components/event.html')));
app.component('cyc-panel', Panel(require('/src/components/panel.html')));
app.component('cyc-icon-stage-type', IconStageType());
app.component('cyc-flag', Flag());
app.component('cyc-bib-flag', bibFlag(require('/src/components/bibFlag.html')));
//app.component('cyc-stage-chart', StageChart(require('/src/components/stageChart.html')));
app.component('cyc-stage-apex-chart', StageApexChart(require('/src/components/stageApexChart.html')));
app.component('cyc-stage-map', StageMap(require('/src/components/stageMap.html')));
app.component('cyc-popup-palmares', PopupPalmares(require('/src/components/popupPalmares.html')));

app.mount('#app');
