import API from '../components/api';
import {isEmpty, formatDate, formatComma} from '../components/tools';

export default (template) => {
	return {
		template,
		watch: {
			'$reloadKey.count'() {
				this.notify();
			},
		},
		data() {
			return {
				competition: null,
				competitionLoading: false,
				competitionError: null,
			};
		},
		async created() {
			await this.getCompetition();

			if (isEmpty(this.competition) === true) {
				return;
			}

			const today = formatDate('now', 'YYYY-MM-DD');

			// redirect today's stage
			for (let stage of this.competition.stages) {
				if (today === stage.date) {
					return this.$router.replace(`/competition/${this.$route.params.id}/${stage.id}`);
				}
			}

			// redirect first stage
			if (isEmpty(this.competition.stages) === false) {
				return this.$router.replace(`/competition/${this.$route.params.id}/${this.competition.stages[0].id}`);
			}
		},
		methods: {
			formatComma,
			async getCompetition() {
				this.competitionLoading = false;
				this.competitionError = null;

				try {
					this.competitionLoading = true;
					this.competition = await API.getCompetition(this.$route.params.id);

					if (isEmpty(this.competition) === false) {
						if (typeof this.competition.language === 'string' && (['en', 'fr'].includes(this.competition.language.toLowerCase()) === true)) {
							this.$i18n.locale = this.competition.language.toLowerCase();
						}
					}
				} catch (exception) {
					this.competitionError = exception.message;
				} finally {
					this.competitionLoading = false;
				}
			},
			notify() {
				console.log('notify!', this.$reloadKey);

				if (isEmpty(this.$reloadKey.message) === true) {
					return;
				}

				if (this.$reloadKey.message.type === 'competition-remove') {
					if (this.$reloadKey.message.id === this.$route.params.id) {
						this.$router.push('/');
					}
				}

				if (this.$reloadKey.message.type === 'competition-update') {
					if (this.$reloadKey.message.id === this.$route.params.id) {
						this.getCompetition();
					}
				}
			},
		},
	};
};
