import API from '../components/api';

export default (template) => {
	return {
		template,
		props: {
			rank: Object,
			rider: Object,
			competition: Object,
			hideTeam: Boolean,
			eventType: String,
		},
		data() {
			return {
			};
		},
		methods: {
			async openRiderModal(rider, ev) {
				//console.log('openRiderModal!', athlete);

				ev.preventDefault();
				ev.stopPropagation();

				try {
					this.$rider.rider = rider;
					this.$rider.details = await API.getRiderDetails(rider.UCIID);

					const elModal = document.getElementById('modal-rider');
					const modal = new window.mdb.Modal(elModal);

					window.setTimeout(() => {
						const elSelect = elModal.querySelector('select');
						if (elSelect) {
							elSelect.firstChild.selected = true;
						}
						const elTabs = document.querySelectorAll('#tab-modal-results .tab-pane');
						if (elTabs && elTabs.length > 0) {
							elTabs.forEach((childNode) => childNode.classList.remove('active'));
							elTabs[0].classList.add('active');
						}
					}, 0);

					modal.show();
				} catch (exception) {
					console.error(exception);
					new window.mdb.Alert(document.querySelector('#alert-rider-not-found')).show();
				}
			},
		},
	};
};
