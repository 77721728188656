export default {
  "competition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no competition"]), _normalize(["competition"]), _normalize([_interpolate(_named("n")), " competitions"])])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["live"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])},
  "distance before arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distance before arrival"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distance"])},
  "gap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gap"])},
  "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speed"])},
  "rider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no rider"]), _normalize([_interpolate(_named("n")), " rider"]), _normalize([_interpolate(_named("n")), " riders"])])},
  "riders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riders"])},
  "stage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no stage"]), _normalize(["stage"]), _normalize([_interpolate(_named("n")), " stages"])])},
  "event": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no event"]), _normalize(["event"]), _normalize([_interpolate(_named("n")), " events"])])},
  "no ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no ranking"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rank"])},
  "_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rnk"])},
  "team": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["not team"]), _normalize(["team"]), _normalize([_interpolate(_named("n")), " teams"])])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])},
  "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonus"])},
  "_bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B."])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrival"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
  "starlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start list"])},
  "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timeline"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
  "rankings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rankings"])},
  "provisional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provisional"])},
  "recent-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent next"])},
  "recent-passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent passed"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "cdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupe de France FDJ"])},
  "cdfW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupe de France Femmes professionnelles FDJ"])},
  "dayPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day pts"])},
  "photo-finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo finish"])},
  "pdfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pdfs"])},
  "penality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penality"])},
  "_penality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P."])},
  "gain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gain"])},
  "Cycling live results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycling live results - Race results"])},
  "rider-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rider information are currently unavailable"])},
  "was-born-W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Born on the"])},
  "was-born-M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Born on the"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
  "strengths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strengths"])},
  "victories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Victories"])},
  "best-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best results"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
  "race": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Race"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "by team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
  "by countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalities"])},
  "jerseyMountain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mountain jersey"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
  "engaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entry list"])},
  "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  ":": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}