export default {
  "competition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucune compétition"]), _normalize(["compétition"]), _normalize([_interpolate(_named("n")), " compétitions"])])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
  "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en direct"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée"])},
  "distance before arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distance avant arrivée"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distance"])},
  "gap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["écart"])},
  "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vitesse"])},
  "rider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["pas de coureur"]), _normalize([_interpolate(_named("n")), " coureur"]), _normalize([_interpolate(_named("n")), " coureurs"])])},
  "riders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coureurs"])},
  "stage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["pas d'étape"]), _normalize(["étape"]), _normalize([_interpolate(_named("n")), " étapes"])])},
  "event": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["pas d'évènement"]), _normalize(["évènement"]), _normalize([_interpolate(_named("n")), " évènements"])])},
  "no ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de classement"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["position"])},
  "_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pos"])},
  "team": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["pas d'équipe"]), _normalize(["équipe"]), _normalize([_interpolate(_named("n")), " équipes"])])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temps"])},
  "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])},
  "_bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B."])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["départ"])},
  "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrivée"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["géneral"])},
  "starlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liste de départ"])},
  "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déroulé"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
  "rankings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classement"])},
  "provisional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provisoire"])},
  "recent-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récentes prochaines"])},
  "recent-passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récentes terminées"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes"])},
  "cdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupe de France FDJ"])},
  "cdfW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupe de France Femmes professionnelles FDJ"])},
  "dayPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pts jour"])},
  "photo-finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo finish"])},
  "pdfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pdfs"])},
  "penality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénalité"])},
  "_penality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P."])},
  "gain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gain"])},
  "Cycling live results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats Vélo Live - Cyclisme Résultats en direct"])},
  "rider-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du coureur actuellement indisponibles"])},
  "was-born-W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Née le"])},
  "was-born-M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Né le"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
  "strengths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forces"])},
  "victories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Victoires"])},
  "best-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleurs résultats"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "race": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "by team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipes"])},
  "by countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalités"])},
  "jerseyMountain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maillot Montagne"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pays"])},
  "engaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["engagés"])},
  "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
  ":": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}