import {isEmpty} from '../components/tools';

export default (template) => {
	return {
		template,
		data() {
			return {
			};
		},
		computed: {
			topResultsSorted() {
				if (isEmpty(this.$rider) === true || isEmpty(this.$rider.details) === true || isEmpty(this.$rider.details.topResults) === true) {
					return [];
				}
				return this.$rider.details.topResults.sort((a, b) => {
					if (a.pos > b.pos) {
						return 1;
					}
					if (a.pos < b.pos) {
						return -1;
					}
					if (a.pos === b.pos) {
						return 0;
					}
				});
			},
		},
		methods: {
			selectRiderModalTab(ev) {
				const tabName = ev.currentTarget.value;

				this.$refs['tab-best-results'].classList.remove('active');

				Object.values(this.$refs).map(ref => {
					if (ref && ref[0]) {
						ref[0].classList.remove('active');
					}
				});

				if (tabName === 'best-results') {
					this.$refs['tab-best-results'].classList.add('active');
				} else {
					this.$refs[`tab-${tabName}`][0].classList.add('active');
				}
			},
			formatPositionType(type) {
				switch (type) {
					case 'JERSEY: MOUNTAIN':
						return this.$t('jerseyMountain');
				}
				return type;
			},
		},
	};
};
