
export default (template) => {
	return {
		props: {
			panel: Object,
			competition: Object,
			stage: Object,
			isCdf: Boolean,
		},
		template,
		data() {
			return {
			};
		},
		created() {
		},
		computed: {
		},
		methods: {
		},
	};
};
