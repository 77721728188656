import {isEmpty, rankingTypeName} from '../components/tools';

export default (template) => {
	return {
		props: {
			event: Object,
			competition: Object,
			stage: Object,
			box: Number,
		},
		template,
		watch: {
			event() {
				this.slicer = this.box;
			},
		},
		data() {
			return {
				slicer: 10,
				btnPlus: false,
				btnMinus: false,
				btnAll: false,
				btnReduce: false,
			};
		},
		computed: {
			ranks() {
				if (isEmpty(this.event.rankings) === true) {
					return [];
				}

				let ranks = this.event.rankings.map((r) => {
					if (r['gap'] === '00:00:00') {
						r['gap'] = null;
					}
					if (r['gap'] === '0') {
						r['gap'] = null;
					}
					if (r['gap'] === 0) {
						r['gap'] = null;
					}
					return r;
				});

				const total = ranks.length;

				if (this.slicer < 0) {
					this.slicer = 0;
				}

				if (this.slicer > total) {
					this.slicer = total;
				}

				this.btnMinus = (this.slicer > this.box);
				this.btnPlus = (this.slicer < total);
				this.btnAll = (this.slicer < total);
				this.btnReduce = (this.slicer === total && this.slicer > this.box);

				ranks = ranks.slice(0, this.slicer);

				return ranks;
			},
		},
		methods: {
			rankingTypeName,
			specialPosition(pos, status) {
				if (pos < 0 || pos > 900) {
					return status;
				}
				return pos;
			},
			getRider(bib) {
				if (typeof this.competition.riders !== 'object' || typeof this.competition.riders[bib] === 'undefined') {
					return {firstname: bib};
				}
				return this.competition.riders[bib];
			},
			getTeam(bib) {
				if (typeof this.competition.teams !== 'object') {
					return {firstname: bib};
				}
				const team = this.competition.teams.find(t => t.position === bib);
				if (isEmpty(team) === true) {
					return {};
				}
				return team;
			},
			hasCol(col) {
				const out = isEmpty(
						this.ranks.find(r => isEmpty(r[col]) === false)
						) === false;
				return out;
			},
			findRankingConfig(type) {
				//console.log('findRankingConfig:', this.event);

				// if already have the rankings in the event object (CDF only)
				if (typeof this.event.rankingTypes === 'object') {
					const config = this.event.rankingTypes.find((r) => r.type === type);
					if (typeof config === 'undefined') {
						return {};
					}
					return config;
				}

				if (typeof this.stage.rankingTypes !== 'object') {
					return {};
				}

				const config = this.stage.rankingTypes.find((r) => r.type === type);
				if (typeof config === 'undefined') {
					return {};
				}
				return config;
			},
			onDiplayPlus() {
				this.slicer += 5;
			},
			onDiplayMinus() {
				this.slicer -= 5;
			},
			onDiplayAll() {
				this.slicer = 99999;
			},
			onDiplayReduce() {
				this.slicer = this.box;
			},
			isPointsEvent(type, isCdf) {
				// Les classement coupe de France sont toujours par points
				if (isCdf === true) {
					return true;
				}
				return ['IPG', 'IMG', 'IPE', 'ISG', 'IME', 'IPA', 'IAG', 'IOE', 'IOG'].includes(type) === true;
			},
			isTeamEvent(type) {
				if (type.startsWith('EQU_') === true) {
					return true;
				}
				return ['ETE', 'ETG'].includes(type) === true;
			},
			displayGain(gain) {
				if (gain === '*') {
					return '<span class="badge badge-primary">NEW</span>';
				}

				if (parseInt(gain) < 0) {
					return `<span class="badge badge-primary"><i class="fa-solid fa-arrow-down" style="color:red"></i>&nbsp;${Math.abs(gain)}</span>`;
				}
				if (parseInt(gain) > 0) {
					return `<span class="badge badge-primary"><i class="fa-solid fa-arrow-up" style="color:green"></i>&nbsp;${Math.abs(gain)}</span>`;
				}

				return '';
			},
			hasRankPosition() {
				if (isEmpty(this.ranks) === true) {
					return false;
				}
				if (isEmpty(this.ranks[0]) === true) {
					return false;
				}
				return isEmpty(this.ranks[0].position) === false;
			},
		},
	};
};
