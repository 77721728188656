import API from '../components/api';
import {rankingTypeName, isEmpty, eventColorBg, eventColorFg, formatComma, eventIcon, getStageEvents} from '../components/tools';

//let tmpRoads = [];

export default (template) => {
	return {
		template,
		watch: {
			'$route.params.stageId'() {
				this.onNewStageId();
			},
			'$route.params.tabId'() {
				this.onNewTabId();
			},
			'$route.params.rankingTabId'() {
				this.onNewTabId();
			},
			'$reloadKey.count'() {
				this.notify();
			},
			'$selectedEvent.id'() {
				this.openEventModal();
			},
			'$selectedEvent.group'() {
				this.setCurrentGroup(this.$selectedEvent.group);
			},
			'currentStage'() {
				// secure full update
				this.improveCompetitionTabs();
			},
		},
		data() {
			return {
				competition: null,
				competitionLoading: false,
				competitionError: null,
				currentStage: null,
				currentGroup: null,
				currentFinishPhoto: null,
				chartColor: null,
			};
		},
		async created() {
			// competitionId: "2021_TDF"
			// stageId: "2021_TDF_STAGE_01"

			////////////////////////////////
			// dev only
			//const LUX_STAGE_01 = await API.getStage('2023_LUX_STAGE_01');
			//tmpRoads = LUX_STAGE_01.roads;
			////////////////////////////////

			await this.getCompetition();
		},
		computed: {
			liveOrTimelineName() {
				return this.$capitalize(this.isCurrentStageLive === true ? this.$t('live') : this.$t('timeline'));
			},
			startListOrEngaggedName() {
				if (isEmpty(this.competition) === false && this.competition.type === 'E') {
					return this.$capitalize(this.$t('engaged'));
				}
				return this.$capitalize(this.$t('starlist'));
			},
			tabs() {
				const tabs = [
					{idx: 'timeline', name: this.liveOrTimelineName, condition: () => (this.rawStageEvents.length > 0)},
					{idx: 'startlist', name: this.startListOrEngaggedName, condition: () => (this.hasCompetitionField('teams') === true)},
					{idx: 'rankings', name: this.$capitalize(this.$t('rankings')), condition: () => (this.currentStage && typeof this.currentStage.rankingTypes !== 'undefined')},
					{idx: 'cdf', name: this.$capitalize(this.$t('cdf')), condition: () => (this.currentStage && typeof this.currentStage.rankingTypes !== 'undefined' && isEmpty(this.competition.tabsCdf) === false)},
					{idx: 'photo-finish', name: this.$capitalize(this.$t('photo-finish')), condition: () => (this.currentStage && typeof this.currentStage.photoFinish !== 'undefined' && this.currentStage.photoFinish.length > 0)},
					{idx: 'pdfs', name: this.$capitalize(this.$t('pdfs')), condition: () => (this.currentStage && typeof this.currentStage.pdfs !== 'undefined' && this.currentStage.pdfs.length > 0)},
				];

				return tabs;
			},
			stageEventsGroupedByKMIdx() {
				const events = Object.keys(this.stageEventsGroupedByKM).sort((a, b) => {
					if (parseFloat(a) > parseFloat(b)) {
						return -1;
					}
					if (parseFloat(a) < parseFloat(b)) {
						return 1;
					}
					if (parseFloat(a) === parseFloat(b)) {
						return 0;
					}
				});

				return events;
			},
			stageEventsGroupedByKM() {
				const sorted = {};

				const stages = this.stageEvents.filter(ev => ev.code !== 'END');
				for (let stage of stages) {
					if (typeof sorted[stage.km] === 'undefined') {
						sorted[stage.km] = [];
					}
					sorted[stage.km].push(stage);
				}

				return sorted;
			},
			rawStageEvents() {
				return this.stageEvents.filter(ev => ['START', 'END'].includes(ev.code) === false);
			},
			stageEvents() {
				return getStageEvents(this.currentStage);
			},
			sortedGroups() {
				if (this.currentStage === null) {
					return null;
				}
				if (isEmpty(this.currentStage.groups) === true) {
					return null;
				}

				// dev only
				//this.currentStage.groups = [...this.currentStage.groups, ...this.currentStage.groups, ...this.currentStage.groups];

				const groups = this.currentStage.groups.sort((a, b) => {
					if (a.position > b.position) {
						return -1;
					}
					if (a.position < b.position) {
						return 1;
					}
					if (a.position === b.position) {
						return 0;
					}
				});

				if (this.currentGroup === null && groups.length > 0) {
					this.currentGroup = groups[groups.length - 1];
				}

				return groups;
			},
			isCurrentStageLive() {
				if (this.currentStage === null) {
					return false;
				}
				if (typeof this.currentStage.start !== 'number') {
					return false;
				}
				// finish can be -0.014
				return (this.currentStage.start !== 0 && this.currentStage.finish !== 0);
			},
			getCompetitionRoads() {
				if (this.currentStage === null) {
					return [];
				}

				/////////////////////////////////////
				//this.currentStage.roads = tmpRoads;
				/////////////////////////////////////

				if (isEmpty(this.currentStage.roads) === true) {
					return [];
				}
				return this.currentStage.roads;
			},
			// si pas en LIVE, afficher le dernier évènement avec résultat ou le 1er si pas de résultats
			getTimelineEvent() {
				const events = this.stageEvents.filter(ev => ['START', 'END'].includes(ev.code) === false);

				let timelineEvent = null;

				for (let stage of events) {
					if (stage.hasResult === true) {
						timelineEvent = stage;
					}
				}

				if (timelineEvent === null && isEmpty(events) === false) {
					timelineEvent = events[0];
				}

				return timelineEvent;
			},
			nationalities() {
				let nat = Object.keys(this.competition.nationalities);

				nat = nat.sort((a, b) => {
					let aCpt = this.competition.nationalities[a].length;
					let bCpt = this.competition.nationalities[b].length;

					return bCpt - aCpt;
				});

				return nat;
			},
			tabsStartlist() {
				return [
					{
						id: 'teams',
						label: this.$t('by team'),
					},
					{
						id: 'countries',
						label: this.$t('by countries'),
					},
				];
			},
		},
		methods: {
			rankingTypeName,
			formatComma,
			eventIcon,
			stageEventsByCode(codes) {
				if (this.currentStage === null) {
					return [];
				}
				if (typeof this.currentStage.rankingTypes === 'undefined') {
					return [];
				}
				return this.currentStage.rankingTypes.filter((e) => codes.includes(e.type) === true);
			},
			async getCompetition() {
				const competitionId = this.$route.params.competitionId;

				this.competitionLoading = false;
				this.competitionError = null;

				try {
					this.competitionLoading = true;
					this.competition = await API.getCompetition(competitionId);

					//this.competition.language = 'FR';
					if (typeof this.competition.language === 'string' && (['en', 'fr'].includes(this.competition.language.toLowerCase()) === true)) {
						this.$i18n.locale = this.competition.language.toLowerCase();
					}

					if (this.isModeIframe() === true && isEmpty(this.competition.iframe) === false) {
						this.chartColor = this.competition.iframe.terceryColor;
						this.updateCSS(this.competition.iframe);
					}

					await this.onNewStageId();
				} catch (exception) {
					this.competitionError = exception.message;
				} finally {
					this.competitionLoading = false;
				}
			},
			updateCSS(iframe) {
				if (isEmpty(iframe.font) === false && iframe.font.toLowerCase() === 'lato') {
					document.body.classList.add(`font-${iframe.font.toLowerCase()}`);
				}

				for (let stylesheet of document.styleSheets) {
					if (stylesheet.href && stylesheet.href.endsWith('theme.css') === true) {
						for (let rule of stylesheet.cssRules) {
							if (rule.selectorText === 'body' && isEmpty(iframe.background) === false) {
								rule.style.setProperty('background', iframe.background, 'important');
							}

							/////// PRIMARY
							if (isEmpty(iframe.primaryColor) === false) {
								if (rule.selectorText === '.bg-primary') {
									rule.style.setProperty('background-color', iframe.primaryColor, 'important');
								}
								if (rule.selectorText === '.cyc-stage-tabs .nav-item .nav-link.active') {
									rule.style.setProperty('background-color', iframe.primaryColor, 'important');
								}
								if (rule.selectorText === '.bg-primary') {
									rule.style.setProperty('background-color', iframe.primaryColor, 'important');
								}
							}

							if (isEmpty(iframe.primaryColorFont) === false) {
								if (rule.selectorText === '.cyc-stage-tabs .nav-item .nav-link.active') {
									rule.style.setProperty('color', iframe.primaryColorFont, 'important');
								}
							}

							/////// SECONDARY
							if (isEmpty(iframe.secondaryColor) === false) {
								if (rule.selectorText === '.bg-secondary') {
									rule.style.setProperty('background-color', iframe.secondaryColor, 'important');
								}
								if (rule.selectorText === '#page-stage .cyc-rankings-tabs .nav-item .nav-link.active') {
									rule.style.setProperty('color', iframe.secondaryColor, 'important');
									rule.style.setProperty('border-color', iframe.secondaryColor, 'important');
								}
								if (rule.selectorText === '.list-group .active .bg-secondary, .stepper-active .stepper-head .badge') {
									rule.style.setProperty('background-color', iframe.secondaryColor, 'important');
								}
							}
							if (isEmpty(iframe.secondaryColorFont) === false) {
								// TODO
							}

							/////// TERCERY
							if (isEmpty(iframe.terceryColor) === false) {
								if (rule.selectorText === '.bg-tercery') {
									rule.style.setProperty('background-color', iframe.terceryColor, 'important');
								}
								if (rule.selectorText === '#page-stage .event-table .datatable-header th') {
									rule.style.setProperty('background-color', iframe.terceryColor, 'important');
								}
								if (rule.selectorText === '.list-group .active .bg-secondary') {
									rule.style.setProperty('background-color', iframe.terceryColor, 'important');
								}
							}
							if (isEmpty(iframe.terceryColorFont) === false) {
								if (rule.selectorText === '#page-stage #rankings-tab-rankings .cyc-panel > .card > .card-header') {
									rule.style.setProperty('color', iframe.terceryColorFont, 'important');
								}
								if (rule.selectorText === '#page-stage .event-table .datatable-header th') {
									rule.style.setProperty('color', iframe.terceryColorFont, 'important');
								}
							}
						}
					}
				}
			},
			hasCompetitionField(field) {
				if (this.competition === null) {
					return false;
				}
				if (typeof this.competition[field] === 'undefined') {
					return false;
				}
				if (isEmpty(this.competition[field]) === true) {
					return false;
				}
				return true;
			},
			async onNewStageId() {
				//console.log('onNewStageId!');

				if (this.competition === null) {
					return;
				}

				this.currentGroup = null;

				this.currentStage = this.competition.stages.find((s) => s.id === this.$route.params.stageId);
				//console.log('this.stage:', this.currentStage);

				if (typeof this.currentStage === 'undefined') {
					this.currentStage = null;
				} else {
					const stageInDb = await this.getStage(this.currentStage.id);
					if (stageInDb !== null) {
						this.currentStage = stageInDb;
						//console.log('this.stage:', this.currentStage.rankingTypes);

						// now we have the stage, we can improve the tabs
						this.improveCompetitionTabs();

						this.openBestTab();
					}
				}
			},
			openBestTab() {
				//console.log('openBestTab:', this.$route.params.tabId, this.currentStage.id, this.$route.params.rankingTabId);

				if (this.$route.params.tabId === '') {
					let bestCurrentTab = 'timeline';

					if (this.tabs.find((i) => i.idx === 'startlist').condition() === true) {
						bestCurrentTab = 'startlist';
					}
					if (this.tabs.find((i) => i.idx === 'rankings').condition() === true) {
						bestCurrentTab = 'rankings';
					}

					return this.$router.replace({params: {...this.$route.params, tabId: bestCurrentTab}});
				}

				if (this.$route.params.tabId === 'rankings' && isEmpty(this.$route.params.rankingTabId) === true) {
					if (isEmpty(this.competition.tabs) === false) {
						const activeTabs = this.competition.tabs.filter((t) => t.displayed === true);
						if (isEmpty(activeTabs) === false) {
							return this.$router.replace({params: {...this.$route.params, tabId: this.$route.params.tabId, rankingTabId: activeTabs[0].id}});
						}
					}
				}

				if (this.$route.params.tabId === 'cdf' && isEmpty(this.$route.params.rankingTabId) === true) {
					if (isEmpty(this.competition.tabsCdf) === false) {
						return this.$router.replace({params: {...this.$route.params, tabId: this.$route.params.tabId, rankingTabId: this.competition.tabsCdf[0].id}});
					}
				}

				if (this.$route.params.tabId === 'startlist' && isEmpty(this.$route.params.rankingTabId) === true) {
					return this.$router.push('/competition/' + this.$route.params.competitionId + '/' + this.$route.params.stageId + '/startlist/teams');
				}
			},
			onNewTabId() {
				//console.log('onNewTabId:', this.currentStage.id, this.$route.params.tabId, this.$route.params.rankingTabId);

				if (this.$route.params.tabId === 'startlist' && isEmpty(this.$route.params.rankingTabId) === true) {
					return this.$router.push('/competition/' + this.$route.params.competitionId + '/' + this.$route.params.stageId + '/startlist/teams');
				}

				// fix: only openBestTab when the loaded stage is the current stageId
				if (this.$route.params.stageId === this.currentStage.id) {

					const mainName = (this.currentStage.name ? this.currentStage.name : this.competition.name);
					document.title = mainName + (this.$route.params.tabId ? ' - ' + this.$route.params.tabId : '') + (this.$route.params.rankingTabId ? ' - ' + this.$route.params.rankingTabId : '');

					this.Stats.trackPage(this.$route.fullPath);

					return this.openBestTab();
				}
			},
			improveCompetitionTabs() {
				if (isEmpty(this.competition.tabs) === false) {
					for (let tab of this.competition.tabs) {
						tab.id = tab.label.replace(/[^A-Za-z0-9]/g, '-');
						tab.displayed = false;
						tab.panelsCount = 0;

						for (let panel of tab.panels) {
							const events = this.stageEventsByCode(panel.eventsCodesFilters);
							panel.displayed = events.length > 0;
							if (panel.displayed === true) {
								tab.displayed = true;
								panel.events = events;
								tab.panelsCount++;
							}
						}
					}

					if (isEmpty(this.competition.tabsCdf) === false) {
						for (let tab of this.competition.tabsCdf) {
							tab.id = tab.label.replace(/[^A-Za-z0-9]/g, '-');
							tab.displayed = false;
							tab.panelsCount = 0;

							for (let panel of tab.panels) {
								const events = this.stageEventsByCode(panel.eventsCodesFilters);
								// mark events as Cdf for special display (event component)
								for (let ev of events) {
									ev.isCdf = true;
								}

								//console.log(panel.eventsCodesFilters, events);
								panel.displayed = events.length > 0;
								if (panel.displayed === true) {
									tab.displayed = true;
									panel.events = events;
									tab.panelsCount++;
								}
							}
						}
					}
				}
			},
			async getStage(stageId) {
				const stage = await API.getStage(stageId);
				//console.log('stage:', stage);
				if (isEmpty(stage) === false) {
					return stage;
				}
				return null;
			},
			getEventRanking(eventId) {
				if (this.currentStage === null) {
					return null;
				}
				if (isEmpty(this.currentStage.rankingTypes) === true) {
					return null;
				}
				const ranking = this.currentStage.rankingTypes.find((r) => r.id === eventId);
				if (typeof ranking === 'undefined') {
					return null;
				}
				return ranking;
			},
			getRider(bib) {
				if (typeof this.competition.riders === 'undefined') {
					return {};
				}
				if (typeof this.competition.riders[bib] === 'undefined') {
					return {};
				}
				return this.competition.riders[bib];
			},
			async notify() {
				console.log('notify!', this.$reloadKey);

				if (isEmpty(this.$reloadKey.message) === true) {
					return;
				}

				if (this.$reloadKey.message.type === 'competition-remove') {
					if (this.$reloadKey.message.id === this.$route.params.competitionId) {
						this.$router.push('/');
					}
				}

				if (this.$reloadKey.message.type === 'competition-update') {
					if (this.$reloadKey.message.id === this.$route.params.competitionId) {
						this.getCompetition();
					}
				}

				if (this.$reloadKey.message.type === 'stage-update') {
					if (this.$reloadKey.message.id === this.$route.params.stageId) {
						this.onNewStageId();
					}
				}

				if (this.$reloadKey.message.type === 'live-group') {
					if (this.$reloadKey.message.id === this.$route.params.stageId) {
						// append the new data
						for (let key of Object.keys(this.$reloadKey.message.data)) {
							this.currentStage[key] = this.$reloadKey.message.data[key];
						}

						// update current group
						if (this.currentGroup !== null) {
							this.currentGroup = this.currentStage.groups.find((g) => g.id === this.currentGroup.id);
						}

						this.improveCompetitionTabs();
					}
				}

				if (this.$reloadKey.message.type === 'teams') {
					if (this.$reloadKey.message.id === this.$route.params.competitionId) {
						this.getCompetition();
					}

				}

				if (this.$reloadKey.message.type === 'live-rankings') {
					if (this.$reloadKey.message.id === this.$route.params.stageId) {
						// append the new data
						for (let key of Object.keys(this.$reloadKey.message.data)) {
							this.currentStage[key] = this.$reloadKey.message.data[key];
						}

						this.improveCompetitionTabs();
					}
				}

				if (this.$reloadKey.message.type === 'live-ranking') {
					//console.log(this.$reloadKey.message.data.id, this.$route.params.stageId);
					if (this.$reloadKey.message.data.id === this.$route.params.stageId) {
						if (typeof this.currentStage.rankingTypes === 'undefined') {
							this.currentStage.rankingTypes = [];
						}
						const dbRankingIdx = this.currentStage.rankingTypes.findIndex((r) => r.id === this.$reloadKey.message.id);
						//console.log('dbRankingIdx:', this.$reloadKey.message.id, dbRankingIdx);

						if (isEmpty(this.$reloadKey.message.data.rankingTypes) === true || this.$reloadKey.message.data.rankingTypes.length !== 1) {
							console.error('Invalid ranking live update');
						} else {
							const ranking = this.$reloadKey.message.data.rankingTypes[0];
							if (dbRankingIdx === -1) {
								this.currentStage.rankingTypes.push(ranking);
							} else {
								this.currentStage.rankingTypes[dbRankingIdx] = ranking;
							}
						}

						this.improveCompetitionTabs();
					}
				}
			},
			displayGap(gap) {
				if (gap.includes(':') === true) {
					return gap + 'min';
				}
				return gap + 'sec';
			},
			setCurrentGroup(group) {
				this.currentGroup = group;
			},
			bgEventCode(code) {
				return `background-color: ${eventColorBg(code)};color: ${eventColorFg(code)};`;
			},
			openPhotoModal(photo) {
				const modalEl = document.querySelector('#photoFinishModal');
				const instance = new window.mdb.Modal(modalEl);

				instance.toggle();

				this.currentFinishPhoto = photo;
			},
			changeRoute(e) {
				this.$router.push('/competition/' + this.$route.params.competitionId + '/' + e.target.value);
			},
			reduceArray(data, size) {
				const array = [...data];
				array.length = Math.min(data.length, size);
				return array;
			},
			openEventModal() {
				if (this.$selectedEvent.id === null) {
					return;
				}

				this.$selectedEvent.event = this.getEventRanking(this.$selectedEvent.id);

				const el = document.getElementById('stageModal');
				const stageModal = new window.mdb.Modal(el);

				if (el.isConfigured !== true) {
					el.addEventListener('hidden.mdb.modal', () => {
						this.$selectedEvent.id = null;
						this.$selectedEvent.event = null;
					});
					el.isConfigured = true;
				}

				stageModal.show();
			},
		}
	};
};
