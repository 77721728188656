import API from '../components/api';
export default (template) => {
	return {
		template,
		watch: {
			async '$route.params.gender'() {
				this.onCreated();
			},
		},
		data() {
			return {
				genderError: null,
				competitionsError: null,
				competitionsLoading: false,
				competitions: [],
				years: [],
			};
		},
		created() {
			document.body.classList.add('coupeDeFrance');
			
			this.onCreated();
		},
		methods: {
			async onCreated() {
				this.genderError = null;
				if (['M', 'W'].includes(this.$route.params.gender) === false) {
					this.genderError = 'Invalid gender';
					return;
				}

				this.getCDFCompetitions();
			},
			async getCDFCompetitions() {
				this.competitionsLoading = false;
				this.competitionsError = null;

				try {
					this.competitionLoading = true;
					this.competitions = await API.getCompetitions({
						coupeDeFrance: true,
						gender: this.$route.params.gender,
						tabsCdf: {
							$ne: null
						},
						$select: ['id', 'name', 'startDate', 'tabsCdf']
					});

					this.years = {};
					for (let competition of this.competitions) {
						const year = new Date(competition.startDate).getFullYear();
						if (typeof this.years[year] === 'undefined') {
							this.years[year] = true;
						}
					}
				} catch (exception) {
					this.competitionsError = exception.message;
				} finally {
					this.competitionsLoading = false;
				}
			},
		}
	};
};
