import Config from '../components/config';

const afterLoad = (code) => {
	window.tarteaucitron.init({
		"privacyUrl": "/mentions-legales",

		"hashtag": "#tarteaucitron",
		"cookieName": "tarteaucitron",

		"orientation": "middle", /* Banner position (top - bottom) */

		"groupServices": false, /* Group services by category */

		"showAlertSmall": false, /* Show the small banner on bottom right */
		"cookieslist": false, /* Show the cookie list */

		"closePopup": false, /* Show a close X on the banner */

		"showIcon": false, /* Show cookie icon to manage cookies */
		"iconSrc": '/cookie-solid.svg', /* Optionnal: URL or base64 encoded image */
		"iconPosition": "BottomLeft", /* BottomRight, BottomLeft, TopRight and TopLeft */

		"adblocker": false, /* Show a Warning if an adblocker is detected */

		"DenyAllCta": true, /* Show the deny all button */
		"AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
		"highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */

		"handleBrowserDNTRequest": true, /* If Do Not Track == 1, disallow all */

		"removeCredit": true, /* Remove credit link */
		"moreInfoLink": false, /* Show more info link */

		"useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
		"useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

		//"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

		"readmoreLink": "", /* Change the default readmore link */

		"mandatory": true,
	});

	window.tarteaucitron.user.matomoId = code;
	window.tarteaucitron.user.matomoHost = 'https://stats.matsport.com/';

	window.tarteaucitron.job = [];
	window.tarteaucitron.job.push('matomo');

	//console.log('window.tarteaucitron.job:', window.tarteaucitron.job);

	if (typeof window.tarteaucitron.services.gtag === 'undefined') {
		//console.log('Force tarteaucitron load!');
		window.tarteaucitron.load();
	}
};

export default  {

	init(code) {
		if (Config.isProd !== true) {
			return;
		}
		afterLoad(code);
	},
	trackPage(to) {
		//console.log('trackPage', to);
		
		window._paq = window._paq || [];
		
		//_paq.push(['setCookieDomain', '*..de']);
		//_paq.push(['setCookieSameSite', 'None']);
		window._paq.push(['setCookieSameSite', 'None']);
		
		window._paq.push(['setDocumentTitle', document.title]);
		window._paq.push(['setCustomUrl', window.location.origin + to]);
		window._paq.push(['trackPageView']);
	},
}
