import API from '../components/api';
import {isEmpty} from '../components/tools';

const dateDiffInDays = (date1, date2) => {
	const milliseconds = (new Date(date1) - new Date(date2));
	const seconds = milliseconds / 1000;
	return seconds / 60 / 60 / 24;
};

export default (template) => {
	return {
		template,
		watch: {
			'$reloadKey.count'() {
				this.notify();
			},
			'$route.params.filter'() {
				this.applyFilter();
			},
		},
		data() {
			return {
				competitions: null,
				competitionsAll: null,
				competitionsLoading: false,
				competitionsError: null,
				search: null,
				filterActive: 'all',
			};
		},
		async created() {
			document.body.classList.remove('coupeDeFrance');

			await this.getCompetitions();

			document.title = this.$t('Cycling live results');
			this.Stats.trackPage(this.$route.fullPath);
		},
		computed: {
			competitionsYears() {
				if (this.competitionsAll !== null) {
					let y = this.competitionsAll.map(c => c.startDate.split('-')[0]);
					y = y.filter((cY) => cY !== undefined);
					// uniq
					return [...new Set(y)].sort();
				}
				return [];
			},
		},
		methods: {
			async getCompetitions() {
				this.competitionsLoading = false;
				this.competitionsError = null;

				try {
					this.competitionsLoading = true;
					this.competitionsAll = await API.getCompetitions();
					this.competitions = this.competitionsAll;

					if (isEmpty(this.$route.params.filter) === false) {
						this.filterActive = this.$route.params.filter;
					} else {
						if (this.competitionsYears.includes(String(new Date().getFullYear())) === true) {
							this.filterActive = String(new Date().getFullYear());
						} else {
							this.filterActive = String(this.competitionsYears[0]);
						}
					}

					this._updateFiltersCompetitions();
				} catch (exception) {
					this.competitionsError = exception.message;
				} finally {
					this.competitionsLoading = false;
				}
			},
			notify() {
				console.log('notify!', this.$reloadKey);

				if (isEmpty(this.$reloadKey.message) === true) {
					return;
				}

				if (['competition-create', 'competition-create', 'competition-update'].includes(this.$reloadKey.message.type) === true) {
					this.getCompetitions();
				}
			},
			onSearchUpdate(ev) {
				this.search = ev.currentTarget.value.toLowerCase();

				this.filterActive = 'all';

				this._updateFiltersCompetitions();
			},
			_updateFiltersCompetitions() {
				//console.log('_updateFiltersCompetitions:', this.filterActive, this.$route.params.filter);
				if (isEmpty(this.search) === false) {
					this.competitions = this.competitionsAll.filter((c) => (c.name.toLowerCase().search(this.search) >= 0));
				}
				if (this.filterActive !== 'all' || isEmpty(this.search) === true) {
					if (this.filterActive !== this.$route.params.filter) {
						this.onDisplayFiltered(this.filterActive);
					} else {
						this.applyFilter();
					}
				}
			},
			onDisplayFiltered(filter) {
				this.$router.push({path: '/' + filter});
			},
			applyFilter() {
				//console.log('applyFilter:', this.$route.params.filter);
				if (isEmpty(this.$route.params.filter) === true) {
					this.$route.params.filter = 'all';
				}

				switch (this.$route.params.filter) {
					case 'all':
						this.competitions = this.competitionsAll;
						break;
					case 'passed':
						this.competitions = this.competitionsAll.filter((c) => {
							const diff = dateDiffInDays(c.startDate, new Date());
							return diff >= -4 && diff < -1;
						});
						break;
					case 'next':
						this.competitions = this.competitionsAll.filter((c) => {
							const diff = dateDiffInDays(c.startDate, new Date());
							return diff >= -1 && diff < 4;
						});
						break;
					default:
						//console.log('filter per year:', filter);
						this.competitions = this.competitionsAll.filter((c) => {
							return new Date(c.startDate).getFullYear() === Number(this.$route.params.filter);
						});
						break;
				}
				this.filterActive = this.$route.params.filter;
			},
		}
	};
};
